import React from "react";
import { Link } from 'gatsby'
import Img from 'gatsby-image'
export default class extends React.Component {
  
  render() {
    const {project} = this.props;
    return (
      <div className="mx-6 mb-10">
        <Img className="featuredImage" sizes={project.frontmatter.featuredImage.childImageSharp.sizes} />
        <h1 className="mt-6 mb-4 text-color">{project.frontmatter.title}</h1>
        <small className="text-black text-sm my-2 block text-color">by <Link className="text-black" to="/about">Gaurav Jassal</Link> on {project.frontmatter.date}</small>
        <div className="my-3 leading-normal text-lg project-content text-color" dangerouslySetInnerHTML={{ __html: project.html }} />
      </div>
    );
  }
}

