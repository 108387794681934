import React from "react";
// import { withPrefix } from 'gatsby'

export default class extends React.Component {
  
  render() {
    const {project} = this.props;
    return (
      <div className="mx-6 article max-w-xl">
        <h1 className="mt-6 mb-4 flex justify-center items-center content-center">
          <span className="flex-1 self-start text-color">{project.frontmatter.title}</span>
          <span className="self-end justify-center mt-3 text-color">
            {project.frontmatter.links.map((item) => {
              let itemSplit = item.split("|");
              let Icon = require("./assets/" +  itemSplit[0] + ".svg")
              return (
                <a href={itemSplit[1]} className="self-center inline-block" rel="noopener noreferrer" target="_blank">
                  <img src={Icon} alt={itemSplit[0]} width="25" className="opacity-100 hover:opacity-25"/>
                </a>
              )
            })}
          </span>
        </h1>

        
        <div className="my-3 leading-normal text-lg project-content text-color" dangerouslySetInnerHTML={{ __html: project.html }} />
      </div>
    );
  }
}

