import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Project from '../components/project';
import Article from '../components/article';


export default ({ data }) => {
  const project = data.markdownRemark
  return (
  <Layout>
    {(project.frontmatter.layout === 'post') ? (
        <Article project={project} />
      ) : (
        <Project project={project} /> 
      )} 
  </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        layout
        description
        category
        tags
        links
        date
        featuredImage {
          childImageSharp{
              sizes(maxWidth: 630, grayscale: true) {
                  ...GatsbyImageSharpSizes
              }
          }
        }
      }
    }
  }
`